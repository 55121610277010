const navLinks = [
  {
    id: 'about',
    title: 'About',
  },
  {
    id: 'experiencess',
    title: 'Experiences',
  },
  {
    id: 'works',
    title: 'Works',
  },
  {
    id: 'contact',
    title: 'Contact',
  },
];

export default navLinks;
