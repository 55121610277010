import React from 'react';

const Footer = () => {
  return (
    <div className="py-10">
      <div className="h-[1px] w-full bg-tertiary"></div>

      <div className="flex items-center justify-center flex-col mt-10 opacity-70">
        <h1 className="text-white">Designeb and Developed by</h1>
        <h1 className="text-white">Azadeh Galidari</h1>
      </div>
    </div>
  );
};

export default Footer;
